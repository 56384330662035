import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { createSearchExpression } from 'src/redux/modules/devProcess.utils';

interface QuickSearchModalProps<T> {
  idKey: keyof T | ((item: T) => React.Key | null | undefined); // TODO: Make sure this is a string?
  isOpen?: boolean;
  items: Array<T>;
  itemType: string;
  onClose: () => void;
  onGoToItem: (item: T) => void;
  renderResultContent: (item: T) => React.ReactNode;
  searchKey: keyof T; // TODO: Make sure this is a string?
}

export function QuickSearchModal<T>({
  idKey,
  isOpen,
  items,
  itemType,
  onClose,
  onGoToItem,
  renderResultContent,
  searchKey,
}: QuickSearchModalProps<T>) {
  const [query, setQuery] = useState('');

  const getIdProperty = useCallback(
    (item: T) => {
      return typeof idKey === 'function' ? idKey(item) : item[idKey];
    },
    [idKey]
  );

  const results = useMemo(() => {
    if (!query) return [];

    const searchExpression = createSearchExpression(query);

    // Track refs so we don't show duplicates in search results
    const resultRefs = new Set<ReturnType<typeof getIdProperty>>();
    const results = items.reduce<Array<T>>((items, item) => {
      const searchProperty = item[searchKey];
      if (typeof searchProperty !== 'string') return items;

      const idProperty = getIdProperty(item);
      if (!resultRefs.has(idProperty) && searchExpression.test(searchProperty)) {
        resultRefs.add(idProperty);
        items.push(item);
      }
      return items;
    }, []);

    return results;
  }, [getIdProperty, items, query, searchKey]);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (results.length && selectedIndex === -1) {
      if (selectedIndex === -1) {
        setSelectedIndex(0);
      }
    } else if (!results.length && selectedIndex > -1) {
      setSelectedIndex(-1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, query]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (selectedIndex > -1) {
        onClose();
        onGoToItem(results[selectedIndex]);
      }
    },
    [onClose, onGoToItem, results, selectedIndex]
  );

  const handleClick = useCallback(
    (item: T) => {
      onClose();
      onGoToItem(item);
    },
    [onClose, onGoToItem]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedIndex(index => (index + results.length + 1) % results.length);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedIndex(index => (index + results.length - 1) % results.length);
      }
    },
    [results.length]
  );

  const resultsContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!resultsContainer.current || selectedIndex === -1) return;

    const element = document.getElementById(
      `QuickSearchModal-${getIdProperty(results[selectedIndex])}`
    );

    element?.scrollIntoView({ block: 'nearest' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={() => setQuery('')}>
      <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6"
              onSubmit={handleSubmit}
            >
              <Dialog.Title as="label" className="font-bold text-gray-800" htmlFor="search">
                Find {itemType}
              </Dialog.Title>
              <div className="mt-1 relative flex items-center">
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="shadow-sm focus:ring-blue-300 focus:border-blue-300 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                  <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                    Esc
                  </kbd>
                </div>
              </div>
              {results.length > 0 && (
                <div className="mt-2 text-xs text-gray-500">
                  Navigate results with
                  <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400 ml-1">
                    ↑
                  </kbd>{' '}
                  <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400 mr-1">
                    ↓
                  </kbd>
                  , press
                  <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400 mx-1">
                    ↵
                  </kbd>
                  to scroll to {itemType}
                </div>
              )}
              {!!query && (
                <div
                  ref={resultsContainer}
                  className="bg-white shadow overflow-hidden rounded-md mt-2 max-h-80vh overflow-y-auto"
                >
                  <ul className="divide-y divide-gray-200">
                    {results.map((item, index) => {
                      const isSelected = index === selectedIndex;
                      const idProperty = getIdProperty(item);
                      return (
                        <li
                          key={idProperty as string}
                          id={`QuickSearchModal-${idProperty}`}
                          className={classNames('cursor-pointer', {
                            'bg-indigo-100': isSelected,
                            'hover:bg-gray-50': !isSelected,
                          })}
                          title={`Scroll to ${itemType}`}
                          onClick={() => handleClick(item)}
                        >
                          <div
                            className={classNames('py-2 px-3 border border-transparent', {
                              'border-indigo-300': isSelected,
                              'rounded-t-md': index === 0,
                              'rounded-b-md': index === results.length - 1,
                            })}
                          >
                            {renderResultContent(item)}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
