import React from 'react';

const InfinityLoader = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <defs>
      <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="rgb(118, 169, 250)" />
        <stop offset="50%" stopColor="rgb(144, 97, 249)" />
        <stop offset="100%" stopColor="rgb(231, 70, 148)" />
      </linearGradient>
    </defs>
    <path
      fill="none"
      stroke="url(#linear)"
      strokeWidth="8"
      strokeDasharray="192.4416961669922 64.14723205566406"
      d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
    >
      <animate
        attributeName="stroke-dashoffset"
        repeatCount="indefinite"
        dur="1s"
        keyTimes="0;1"
        values="0;256.58892822265625"
      ></animate>
    </path>
  </svg>
);

export default InfinityLoader;
