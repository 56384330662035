import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import IconStar from 'src/svg/icon-check-circle.svg';
import type { DropdownBase } from 'src/types';
import { debounce } from 'lodash';

export const TableContainer = props => (
  <div className="align-middle inline-block min-w-full overflow-auto shadow sm:rounded-lg border-b border-gray-200">
    {props.children}
  </div>
);

export const Table: React.FC<React.HTMLProps<HTMLTableElement>> = props => (
  <table className="min-w-full table-auto">{props.children}</table>
);

export const Thead: React.FC<React.HTMLProps<HTMLTableSectionElement>> = props => (
  <thead>{props.children}</thead>
);

export const Tbody: React.FC<React.HTMLProps<HTMLTableSectionElement>> = props => (
  <tbody className="bg-white">{props.children}</tbody>
);

export const Tr: React.FC<React.HTMLProps<HTMLTableRowElement>> = ({ className, ...props }) => {
  return (
    <tr
      {...props}
      className={classNames(className, 'border-b last:border-0 hover:bg-gray-50 border-gray-200')}
    >
      {props.children}
    </tr>
  );
};

export const Th: React.FC<React.HTMLProps<HTMLTableCellElement>> = ({ className, ...props }) => {
  return (
    <th
      {...props}
      className={classNames(
        className,
        'px-4 py-3 text-left font-medium text-gray-400 uppercase tracking-wider text-sm leading-5'
      )}
    >
      {props.children}
    </th>
  );
};

export const Td: React.FC<React.HTMLProps<HTMLTableCellElement>> = ({ className, ...props }) => {
  return (
    <td {...props} className={classNames(className, 'leading-2 text-sm px-4 py-3')}>
      {props.children}
    </td>
  );
};

export const ButtonGroup = ({ className, ...props }) => {
  const optionButtons = props.options.map((option, index) => {
    const cn = classNames(
      '-ml-px relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-xs leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150',
      {
        'rounded-l-md': index === 0,
        'rounded-r-md': index === props.options.length - 1,
        'border-indigo-300 text-indigo-700 bg-indigo-100': option.active,
      }
    );

    return (
      <button
        className={cn}
        key={option.label}
        onClick={() => {
          props.onClick(option);
        }}
      >
        {option.label}
      </button>
    );
  });

  return (
    <div className={className}>
      <div
        className={classNames(
          className,
          'text-gray-400 text-xs font-medium py-1 inline-flex mr-5 mb-5'
        )}
      >
        {props.label || 'Filter'}
      </div>
      {optionButtons}
      {props.children}
    </div>
  );
};

interface BoxHeaderProps {
  className?: string;
  prefix?: string;
  options?: ReadonlyArray<DropdownBase>;
  title: string;
  tabs?: DropdownBase;
  onOptionChange?: (option: DropdownBase) => void;
}

export const BoxHeader = ({ className, ...props }: BoxHeaderProps) => {
  const { options, title, prefix } = props;
  return (
    <div className={classNames(className, 'border-b px-6')}>
      <div className="flex justify-between -mb-px">
        <div className="lg:flex">
          {!!title && <h3 className="text-gray-800 py-4 font-bold mr-3">{title}</h3>}
          {!!prefix && <h3 className="text-gray-400 py-4 font-bold mr-3">{prefix}</h3>}
        </div>
        {!!options?.length && (
          <div className="flex text-xs">
            {options.map((option, index) => {
              const sectionClass = classNames(
                'appearance-none font-medium text-gray-400 uppercase tracking-wider py-4 border-b-2 mr-3',
                {
                  'border-blue-600 text-blue-600 font-bold': option.active,
                  'border-transparent hover:border-gray-500': !option.active,
                }
              );
              return (
                <button
                  key={index}
                  type="button"
                  className={sectionClass}
                  onClick={() => props.onOptionChange && props.onOptionChange(option)}
                >
                  {option.label}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

interface BoxLoadMoreProps {
  children?: React.ReactNode;
  className?: string;
  href?: string;
  to?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const BoxLoadMore: React.FC<BoxLoadMoreProps> = ({
  className: classNameFromprops,
  children,
  ...props
}) => {
  const className = classNames(
    classNameFromprops,
    'block py-3 px-4 hover:bg-blue-50 text-sm text-center rounded-b text-gray-400 hover:text-gray-800 cursor-pointer'
  );
  if (props.to) {
    return (
      <GatsbyLink {...props} to={props.to} className={className}>
        {children || 'Show more'}
      </GatsbyLink>
    );
  }

  if (props.href) {
    return (
      <a rel="noopener noreferrer" target="_blank" {...props} className={className}>
        {children || 'Show more'}
      </a>
    );
  }

  return (
    <div {...props} className={className}>
      {children || 'Show more'}
    </div>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  title: string;
  prefix?: string;
  onLoadMore?: () => void;
  onOptionChange?: (option: DropdownBase) => void;
  options?: ReadonlyArray<DropdownBase>;
  className?: string;
}

export const Box: React.FC<BoxProps> = ({ className, children, onLoadMore, ...props }) => {
  // return <div className={className} {...cleanProps} />;
  // 'mb-4 border rounded-md shadow cursor-pointer transition duration-100 ease-in-out',
  return (
    <div className={classNames(className, 'bg-white rounded-md border shadow')}>
      <BoxHeader
        title={props.title}
        prefix={props.prefix}
        options={props.options}
        onOptionChange={props.onOptionChange}
      />
      {children}
      {!!onLoadMore && <BoxLoadMore onClick={onLoadMore} />}
    </div>
  );
};

// Form Stuff
interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'size'> {
  label?: string;
  prefixIcon?: React.ReactElement;
  size?: 'sm' | 'md' | 'lg';
  withDebounce?: boolean;
}

export const Input = ({ className: classNameFromProps, ...props }: InputProps) => {
  const [value, setValue] = useState(props.value);

  // One-way sync with Redux value
  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const { prefixIcon, withDebounce, onChange, size, value: _discardedValue, ...rest } = props;

  const handleChangeCallback = useMemo(() => {
    return withDebounce && onChange != null
      ? debounce(onChange, 100, { leading: false, trailing: true })
      : onChange;
  }, [onChange, withDebounce]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setValue(value);
      e.persist();
      handleChangeCallback?.(e);
    },
    [handleChangeCallback]
  );

  return (
    <label className="relative block">
      <input
        {...rest}
        onChange={handleChange}
        value={value}
        className={classNames(
          classNameFromProps,
          'border w-full text-gray-700 focus:outline-none focus:bg-white focus:border-blue-300 focus:ring  transition ease-in-out duration-150 rounded-full',
          {
            'px-2 py-1 text-sm pl-7': props.size === 'sm',
            'px-4 py-2 leading-tight': !props.size || props.size === 'md',
            'pl-10': props.size !== 'sm',
          }
        )}
      />
      {!!prefixIcon && (
        <span className={classNames('absolute top-0 left-0 ml-2 mt-2 inline-block')}>
          {prefixIcon}
        </span>
      )}
    </label>
  );
};

export const SearchInput = (props: InputProps) => {
  return (
    <Input
      {...props}
      prefixIcon={
        <svg
          viewBox="0 0 24 24"
          className={classNames({
            'w-4 h-4': props.size === 'sm',
            'w-6 h-6': !props.size || props.size === 'md',
          })}
        >
          <path
            fill="#bbb"
            d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
          ></path>
        </svg>
      }
    />
  );
};

interface HeroHeaderProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
}

export const HeroHeader: React.FC<HeroHeaderProps> = ({ className, ...props }) => {
  return (
    <header
      className={classNames(
        'bg-white py-4 px-4 lg:px-8 relative z-index-10 flex border-b',
        className
      )}
      style={props.style}
    >
      {!!props.title && (
        <div className="flex-1 mr-4">
          <h2 className="text-3xl font-bold leading-tight text-gray-900">{props.title}</h2>
        </div>
      )}
      {props.children}
    </header>
  );
};

export const Bulletpoint = ({ title }: { title: string }) => {
  return (
    <div key={title} className="mt-4 flex  leading-6 text-md">
      <IconStar
        className="flex-shrink-0 mr-1.5 mt-0.5 h-5 w-5 text-green-400"
        fill="currentColor"
      />
      {title || '?????'}
    </div>
  );
};

export const BackgroundPattern = ({
  width = 125,
  height = 125,
}: {
  height?: number;
  width?: number;
}) => {
  const side = 'left';
  const id = `64e643ad-2176-4f86-b3d7-f2c5da3b6a6d-${side}`;
  const containerClass = classNames(`absolute transform`, {
    'right-full translate-x-1/2 -translate-y-1/2 z-0': side === 'left',
  });

  return (
    <div className="relative hidden lg:block">
      <svg
        className={containerClass}
        width={width}
        height={height}
        fill="none"
        viewBox={`0 0 ${width} ${height}`}
        aria-hidden="true"
      >
        <defs>
          <pattern id={id} x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width={width} height={height} fill={`url(#${id})`} />
      </svg>
    </div>
  );
};

export const FeaturedSection = ({
  title,
  children,
  description,
}: {
  title: string;
  children?: React.ReactNode;
  description?: string;
}) => {
  return (
    <div className="text-xl text-gray-500">
      <h3 className="text-4xl font-extrabold text-gray-900 tracking-tight z-10 relative mb-3">
        {title}
      </h3>
      <BackgroundPattern />

      <div className="z-10 relative">
        {!!description && <p className="mb-3">{description}</p>}
        {children}
      </div>
    </div>
  );
};

export { Avatar } from './Avatar';
export { Badge } from './Badge';
export { Button } from './Button';
export { Checkbox } from './Checkbox';
export { Link } from './Link';
