import React from 'react';
import classNames from 'classnames';

export const Checkbox: React.FC<React.HTMLProps<HTMLInputElement>> = ({
  checked,
  children,
  className,
  ...props
}) => {
  return (
    <span
      className={classNames(
        'inline-flex items-center justify-center flex-none w-4 h-4 r-2 rounded',
        {
          'bg-blue-500': checked,
          'border-2 border-blue-500': !checked,
        },
        className
      )}
      {...props}
    >
      {checked && (
        <svg
          width="11"
          height="8"
          viewBox="0 0 11 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4.3891L3.82843 7.21753L9.48528 1.56067"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </span>
  );
};
