import React from 'react';
import classNames from 'classnames';
import { Link } from './Link';

const buttonSizes = {
  default: 'px-4 py-2 text-base font-medium',
  xs: 'p-0 text-xs',
  sm: 'px-2 py-1 text-sm',
};

interface Props extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  isActive?: boolean;
  href?: string;
  newWindow?: boolean;
  noFocus?: boolean;
  size?: keyof typeof buttonSizes;
}

export const Button: React.FC<Props> = ({ className: classNameFromProps, noFocus, ...props }) => {
  const className = classNames(
    'inline-flex items-center justify-center no-underline rounded-md border bg-white leading-6 shadow-sm hover:shadow hover:text-gray-500 transition ease-in-out duration-150 sm:leading-5',
    buttonSizes[props.size || 'default'],
    {
      'text-white border-blue-300 bg-blue-200': props.isActive,
      'text-gray-700 border-gray-300 bg-white-500': !props.isActive,
      'focus:outline-none focus:border-blue-300 focus:ring': !noFocus,
    },
    classNameFromProps
  );

  if (props.href) {
    return <Link {...props} className={className} />;
  }

  return <button {...props} className={className} type="button" />;
};
