import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  Avatar,
  Button,
  BoxLoadMore,
} from '../Library';
import { fetchAirtableTools } from 'src/api';
import { EmptyBox } from '../Placeholders';
import { naturalCompare } from 'src/utils/sort';
import { getApiOptionsWithAuthHeader } from 'src/redux/modules/auth.selectors';
import { redirectToLogin } from 'src/redux/modules/auth.actions';
import type { AppDispatch } from 'src/redux/store';

type OptionTypes = 'recent' | 'all';

interface ToolBoxProps {
  title?: string;
  compact?: boolean;
  defaultOption?: OptionTypes;
}

const ToolBox = (props: ToolBoxProps) => {
  const [activeOption, setActiveOption] = useState(props.defaultOption || 'recent');
  const [data, setData] = useState<Array<any>>([]);
  const apiOptionsWithAuthHeader = useSelector(getApiOptionsWithAuthHeader);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!apiOptionsWithAuthHeader) {
      dispatch(redirectToLogin());
      return;
    }

    fetchAirtableTools(apiOptionsWithAuthHeader)
      .then(res => {
        setData(res);
      })
      .catch(error => console.log(error));
  }, [apiOptionsWithAuthHeader, dispatch]);

  const options = [
    { value: 'recent', label: 'Recently Updated', active: activeOption === 'recent' },
    { value: 'all', label: 'All Tools', active: activeOption === 'all' },
  ] as const;

  const tools =
    activeOption === 'recent'
      ? data.sort((a, b) => b.updated_at - a.updated_at).slice(0, 5)
      : data.sort((a, b) => naturalCompare(a.name, b.name));

  return (
    <Box
      title="Tools We Use At Timely"
      onOptionChange={option => setActiveOption(option.value as typeof activeOption)}
      options={options}
    >
      {!tools.length ? (
        <EmptyBox />
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              {!props.compact && (
                <React.Fragment>
                  <Th>Yearly Price</Th>
                </React.Fragment>
              )}
              <Th>Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {tools.map(row => {
              return (
                <Tr key={row.id}>
                  <Td>
                    <div className="flex">
                      <div className="mr-1 w-8 pt-1">{!!row.icon && <Avatar src={row.icon} />}</div>
                      <div className="flex-1">
                        <div className="font-semibold">{row.name}</div>
                        <p className="text-gray-400">{row.how_to_use}</p>
                      </div>
                    </div>
                  </Td>

                  {!props.compact && (
                    <Td>
                      {!!row.price_per_mo &&
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(row.price_per_mo * 12)}
                    </Td>
                  )}
                  <Td>
                    {row.status?.map(status => (
                      <Badge key={status}>{status}</Badge>
                    ))}
                    {/* {!!row.updated_at && (
                      <div>{formatRelative(new Date(row.updated_at), 'yyyy-MM-dd')}</div>
                    )} */}
                  </Td>
                  <Td>
                    <div className="text-right">
                      {!!row.url_login && (
                        <Button newWindow href={row.url_login}>
                          Login
                        </Button>
                      )}
                      {!!row.url && (
                        <Button href={row.url} className="ml-2" newWindow>
                          Visit
                        </Button>
                      )}
                    </div>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
      {!!data.length && (
        <BoxLoadMore href="https://airtable.com/shrZAo7UKtEDv4biJ">
          Open Airtable Document
        </BoxLoadMore>
      )}
    </Box>
  );
};

export default ToolBox;
