import React from 'react';
import RefreshIcon from 'src/svg/icon-refresh.svg';

interface Props {
  title?: string;
}

export const EmptyBox = (props: Props) => {
  return (
    <div className="grid justify-center  w-full text-gray-200 p-20">
      <RefreshIcon className="w-12 h-12 fill-current m-auto block mb-2 " />
      <div className="text-center text-gray-400">{props.title || 'Loading...'}</div>
    </div>
  );
};

export default EmptyBox;
