import React, { useLayoutEffect, useRef } from 'react';
import IconClock from 'src/svg/icon-clock.svg';
import { Avatar, Badge, Table, Thead, Tr, Th, Tbody, Td, Link } from 'src/components/Library';
import { format, formatRelative } from 'date-fns';
import Emoji from 'react-emoji-render';
import type { Person } from 'src/types';
import classNames from 'classnames';

interface PersonCardProps {
  className?: string;
  person: Person;
}

export const PersonName = ({ person, className }: PersonCardProps) => {
  return !!person.externalReference.slackId && !!person.externalReference.slackTeamId ? (
    <Link
      className={classNames('no-underline hover:underline', className)}
      href={`slack://user?team=${person.externalReference.slackTeamId}&id=${person.externalReference.slackId}`}
    >
      {person.name}
    </Link>
  ) : className ? (
    <span className={className}>{person.name}</span>
  ) : (
    <>{person.name}</>
  );
};

export const PersonStatus = ({ person, className }: PersonCardProps) => {
  const emojiRef = useRef<HTMLSpanElement>(null);

  // Remove any emoji patterns that `react-emoji-render` was unable to render
  useLayoutEffect(() => {
    if (!person.imStatus.emoji || !emojiRef.current) return;
    emojiRef.current.querySelector('span')?.childNodes.forEach(childNode => {
      if (childNode.nodeType === Node.TEXT_NODE) {
        childNode.parentNode?.removeChild(childNode);
      }
    });
  }, [person.imStatus.emoji]);

  if (!person.imStatus.emoji) return null;

  const isInDewoMode = person.imStatus.emoji === ':dewo_icon:';
  return (
    <span
      ref={emojiRef}
      className={classNames('ml-1', className, {
        'hint--bottom-right': !!person.imStatus.text,
        'inline-flex items-center': isInDewoMode,
      })}
      aria-label={person.imStatus.text}
    >
      {isInDewoMode ? (
        <img src="/logos/dewo.svg" className="ml-1 h-4" />
      ) : (
        <Emoji text={person.imStatus.emoji} className="inline-flex" />
      )}
    </span>
  );
};

export const PersonCard = (props: PersonCardProps) => {
  const { person } = props;
  const localTime = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: person?.tz || undefined,
    })
  ).toISOString();

  return (
    <div className="bg-white shadow relative rounded p-5">
      <div className="relative -mt-10">
        <Avatar src={person.avatar} className="w-10 h-10" />
      </div>
      <div className="text-gray-400">
        <h2 className="text-gray-800 font-semibold">
          <PersonName person={person} />
          <PersonStatus person={person} />
        </h2>
        <div className="text-xs">
          <p className=" mb-1">
            <span className="font-semibold">{person.title || '??'},</span>{' '}
            {person.department || '??'},{' '}
            <span className="whitespace-nowrap">{person.location || '??'}</span>
          </p>
          <p className="mb-1">
            <IconClock className="w-4 h-4 mr-1 mb-1 fill-current inline-block" />
            Local time is {formatRelative(new Date(localTime), new Date())}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap gap-1">
        {person.leave.map(leave => {
          if (leave.activeLeave) {
            return (
              <Badge key={`${person.email}-back-on-${leave.resumesWork}`} variant="red">
                Back on {format(new Date(leave.resumesWork), 'MMMM d')}
              </Badge>
            );
          }
          if (leave.start === leave.end) {
            return (
              <Badge key={`${person.email}-away-${leave.end}`} variant="yellow">
                Away {format(new Date(leave.end), 'MMMM d')}
              </Badge>
            );
          }
          return (
            <Badge key={`${person.email}-away-${leave.start}-${leave.end}`} variant="yellow">
              Away {format(new Date(leave.start), 'MMMM d')} -{' '}
              {format(new Date(leave.end), 'MMMM d')}
            </Badge>
          );
        })}
      </div>
    </div>
  );
};

interface PeopleTableProps {
  people: Array<Person>;
}

export const PeopleTable = ({ people }: PeopleTableProps) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>About</Th>
          <Th>When &amp; Where</Th>
        </Tr>
      </Thead>
      <Tbody>
        {people.map(person => {
          const localTime = new Date(
            new Date().toLocaleString('en-US', {
              timeZone: person?.tz || undefined,
            })
          ).toISOString();
          return (
            <Tr key={person.email}>
              <Td>
                <div className="flex">
                  <div className="flex-0 mr-3">
                    <Avatar src={person.avatar} className="w-10 h-10" />
                  </div>
                  <div className="flex-1">
                    <div className="text-sm leading-5 font-medium">
                      <PersonName person={person} />
                      <PersonStatus person={person} />
                    </div>
                    <span className="text-sm leading-5 text-gray-500">{person.title || '??'}</span>
                  </div>
                </div>
              </Td>
              <Td>
                <span className="flex flex-wrap gap-1">
                  {person.leave.map((leave, idx) => {
                    if (leave.activeLeave) {
                      return (
                        <Badge variant="red" key={idx}>
                          Back on {format(new Date(leave.resumesWork), 'MMMM d')}
                        </Badge>
                      );
                    }
                    if (leave.start === leave.end) {
                      return (
                        <Badge key={idx} variant="yellow">
                          Away {format(new Date(leave.end), 'MMMM d')}
                        </Badge>
                      );
                    }
                    return (
                      <Badge variant="yellow" key={idx}>
                        Away {format(new Date(leave.start), 'MMMM d')} -{' '}
                        {format(new Date(leave.end), 'MMMM d')}
                      </Badge>
                    );
                  })}
                  {person.teams?.map(team => (
                    <Badge key={team.name}>
                      {team.emoji} {team.name}
                    </Badge>
                  ))}
                </span>
              </Td>
              <Td>
                {formatRelative(new Date(localTime), new Date())} @ {person.location}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
