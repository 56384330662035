import React from 'react';
import classNames from 'classnames';

const badgeVariants = {
  blue: 'bg-blue-100 text-blue-700',
  dewo: 'bg-green-100 text-green-700',
  'second-brain': 'bg-orange-100 text-orange-700',
  gray: 'bg-gray-200 text-gray-600',
  pink: 'bg-pink-100 text-pink-700',
  red: 'bg-red-100 text-red-700',
  timely: 'bg-purple-100 text-purple-700',
  yellow: 'bg-yellow-100 text-yellow-900',
} as const;

export type BadgeVariant = keyof typeof badgeVariants;

interface BadgeProps extends React.HTMLProps<HTMLDivElement> {
  icon?: React.ReactElement;
  variant?: BadgeVariant;
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  className,
  variant = 'gray',
  icon,
  title,
}) => {
  return (
    <div
      className={classNames(
        `inline-flex rounded align-center text-xs`,
        badgeVariants[variant] ?? badgeVariants['gray'],
        {
          'hint--bottom': !!title,
        }
      )}
      aria-label={title}
    >
      {React.isValidElement<any>(icon) &&
        React.cloneElement(icon, {
          className: `${icon.props.className} rounded rounded-r-none ${className}`,
        })}
      <span className="mx-1 my-0.5">{children}</span>
    </div>
  );
};
