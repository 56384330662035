import React from 'react';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';

export const LINK_CLASS_NAMES = 'underline hover:text-indigo-600 focus:outline-none';

export const Link = ({ className, newWindow = false, ...props }) => {
  const embedNewWindow = !!newWindow && { target: '_blank ' };

  // Native Gatsby Link
  if (props.to) {
    return (
      <GatsbyLink
        {...embedNewWindow}
        {...props}
        to={props.to}
        className={classNames(LINK_CLASS_NAMES, className, {
          'inline-flex items-center': !className?.includes('block'),
        })}
      >
        {props.children}
      </GatsbyLink>
    );
  }
  return (
    <a
      {...embedNewWindow}
      {...props}
      href={props.href}
      className={classNames(LINK_CLASS_NAMES, className, {
        'inline-flex items-center': !className?.includes('block'),
      })}
    >
      {props.children}
    </a>
  );
};
