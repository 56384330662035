import React from 'react';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  alt?: string;
  circular?: boolean;
  sizeClassName?: string;
  src?: string;
}

export const Avatar = ({
  circular,
  className: classNameFromProps,
  sizeClassName,
  ...props
}: Props) => {
  const className = classNames(
    'bg-gray-200 text-center inline-block text-white shadow object-cover',
    classNameFromProps,
    sizeClassName ?? 'w-6 h-6',
    {
      'rounded-full': circular,
      rounded: !circular,
    }
  );

  if (!props.src) {
    return (
      <div {...props} className={className}>
        {props.alt?.substring(0, 1) || ''}
      </div>
    );
  }

  return <img alt="" {...props} className={className} />;
};
